import DASHBOARD from './dashboard';
import NHS_PATIENT_DASHBOARD from './nhs_patient_dashboard';
import NAVIGATION from './navigation';
import TEST from './test';
import CHAT from './chat';
import WEIGHTDETAILS from './weightDetails';
import FOODDETAILS from './foodDetails';
import MOODDETAILS from './moodDetails';
import EXERCISEDETAILS from './exerciseDetails';
import DAILYREPORTDETAILS from './dailyReportDetails';
import SMARTGOALSWEIGHT from './smartGoalsWeigh';
import FOODQUESTIONNAIRE from './foodQuestionnaire';
import COMMUNITY from './communityDetails';
import ACTIVITY_SUMMARY from './activity_summary';
import NHS_PRACTITIONER_TICKET from './nhs_practitioner_ticket';
import TICKET from './ticket';
import FOODLOGS from './foodLogs';
import MOODLOGS from './moodLogs';
import EXERCISELOGS from './exerciseLogs';
import FILESTAGS from './fileTags';
import LIBRARYFILES from './libraryFiles';
import MY_FILES_DASHBOARD from './myFiles';

// Inside my proptypes.js file
import PropTypes, { oneOfType, arrayOf, shape, number, string } from 'prop-types';

export const widget_structure = {
	content: string.isRequired,
	breakpoints: shape({
		xs: number,
		sm: number,
		md: number,
		xl: number,
	}).isRequired,
	lazy_loading: number /** miliseconds */,
};

export const catalogue_structure = {
  name: string /** Options: name of catalogue */,
	sx: oneOfType([arrayOf(oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]) /** MUI sx prop */,
	spacing: number /** Theme spacing options */,
	rowSpacing: number /** Theme rowSpacing options */,
	columnSpacing: number /** Theme columnSpacing options */,
	direction: string /** Options: row, row-reverse, column, column-reverse */,
	justifyContent: string /** Options: flex-start, center, flex-end, space-between, space-around, space-evenly */,
	alignItems: string /** Options: flex-start, center, flex-end, stretch, baseline */,
	widgets: arrayOf(shape(widget_structure)).isRequired,
};

export default {
	DASHBOARD,
	NAVIGATION,
	TEST,
	CHAT,
	WEIGHTDETAILS,
	FOODDETAILS,
	MOODDETAILS,
	EXERCISEDETAILS,
	DAILYREPORTDETAILS,
	SMARTGOALSWEIGHT,
	FOODQUESTIONNAIRE,
	NHS_PATIENT_DASHBOARD,
	COMMUNITY,
	ACTIVITY_SUMMARY,
	TICKET,
	NHS_PRACTITIONER_TICKET,
	FOODLOGS,
	MOODLOGS,
	EXERCISELOGS,
	FILESTAGS,
  LIBRARYFILES,
  MY_FILES_DASHBOARD,
};
