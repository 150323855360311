import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';

import { isIOS } from 'react-device-detect';
import { isMobileSize } from '../../helpers/functions/functions';
import { AppBar, Tabs, Tab, Typography, Box, Paper } from '@mui/material';
import { push } from 'connected-react-router';
import { Alert } from '@mui/material';

import LoginForm from './LoginForm';
import LoginBankID from './LoginBankID';
import LoginFrejaID from './LoginFrejaID';
import LoginSITHS from './LoginSITHS';
import BankIDlogo from '../../assets/images/bankid.png';
import FrejaIDlogo from '../../assets/images/frejaid.png';
import NetIDlogo from '../../assets/images/netid.png';

import TabContainer from '../Generic/TabContainer';

import { bankIDQRGeneratorAction } from './actions/loginActions';

class LoginTabs extends Component {
	constructor(props) {
		super(props);

		this.loginMethods = this.props.settings.supported_login_methods
			? this.props.settings.supported_login_methods
					.replace(/,\s*$/, '')
					.split(',')
					.map((item) => item.trim())
					.filter((item) => item.length > 0)
			: [];

		if (this.props.platform === 'backoffice' && this.props.settings.supported_login_methods_backoffice) {
			this.loginMethods = this.props.settings.supported_login_methods_backoffice
				.replace(/,\s*$/, '')
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.length > 0);
		}

		this.state = {
			selected:
				typeof this.props.settings.preferred_login_method !== 'undefined'
					? this.props.settings.preferred_login_method
					: this.loginMethods.length > 0
					? this.loginMethods[0]
					: 'password',
		};
	}

	handleChange = (event, newValue) => {
		if (
			newValue === 'bankid' &&
			this.props.client !== null &&
			this.props.client.id != '0' &&
			this.loginMethods.some((item) => item == 'bankid_qrcode') &&
			!isMobileSize()
		) {
			this.props.bankIDQRGeneratorAction(this.props.selectedLanguage);
		}

		this.setState({
			selected: newValue,
		});

		this.props.push('/login');
	};

	render() {
		const { translate } = this.props;
		const { theme } = this.props;

		const messageBlock =
			this.props.message.error !== '' ? (
				<Box component="span">
					<Typography variant="body2" color="error" align="center">
						{this.props.message.error}
					</Typography>
				</Box>
			) : null;

		return (
			<Paper elevation={this.props.client !== null && this.props.client.id == '0' ? 3 : 0} className="login-content-right-content-tabs">
				{this.loginMethods.length > 0 ? (
					this.loginMethods.length > 1 ? (
						<Tabs
							sx={{ borderBottom: 1, borderColor: 'divider' }}
							value={this.state.selected}
							onChange={this.handleChange}
							indicatorColor="secondary"
							textColor="primary"
							variant="fullWidth"
							scrollButtons="auto"
              centered
						>
							{this.loginMethods
								.filter((item) => item !== 'bankid_qrcode')
								.map((method, key) => (
									<Tab key={key} label={translate('login_tab_' + method)} value={method} />
								))}
						</Tabs>
					) : null
				) : null}
				{this.loginMethods.includes('password') ? (
					<TabContainer value={this.state.selected} index="password" dir={theme.direction} style={{ paddingTop: '30px' }}>
						{messageBlock}
						<LoginForm
							selectedLanguage={this.props.selectedLanguage}
							recovery={this.props.recovery}
							recovery_token={this.props.recovery_token}
              recovery_medium={this.props.recovery_medium}
              recovery_value={this.props.recovery_value}
							type="login"
						/>
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('bankid') ? (
					<TabContainer value={this.state.selected} index="bankid" dir={theme.direction} style={{ paddingTop: '30px' }}>
						{isIOS ? (
							<Alert className="login-bankid-alert-infobox" severity="info">
								{' '}
								{translate('login_bankid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<Box mb={2}>
							<Typography align="center" variant="body2" component="p" gutterBottom>
								{translate('user_register_banck_id_info')}
							</Typography>
						</Box>
						<img src={BankIDlogo} height="70" alt="BankID logo" />
						{messageBlock}
						<LoginBankID selectedLanguage={this.props.selectedLanguage} type="login" />
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('frejaid') ? (
					<TabContainer value={this.state.selected} index="frejaid" dir={theme.direction} style={{ paddingTop: '30px' }}>
						{isIOS ? (
							<Alert className="login-frejaid-alert-infobox" severity="info">
								{' '}
								{translate('login_frejaid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<Box mb={2}>
							<Typography align="center" variant="body2" component="p" gutterBottom>
								{translate('user_register_frejaid_info')}
							</Typography>
						</Box>
						<img src={FrejaIDlogo} height="70" alt="FrejaID logo" />
						{messageBlock}
						<LoginFrejaID selectedLanguage={this.props.selectedLanguage} type="login" />
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('siths') ? (
					<TabContainer value={this.state.selected} index="siths" dir={theme.direction} style={{ paddingTop: '30px' }}>
						<img src={NetIDlogo} height="70" alt="NetID logo" />
						{messageBlock}
						<LoginSITHS selectedLanguage={this.props.selectedLanguage} />
					</TabContainer>
				) : (
					false
				)}
			</Paper>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.site,
	translate: getTranslate(state.localize),
	client: state.login.client,
});

export default withStyles(null, { withTheme: true })(connect(mapStateToProps, { bankIDQRGeneratorAction, push })(LoginTabs));
