import React, { Component } from 'react';
import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

class PanelList extends Component {
    state = {
        expanded: false
    };

    componentDidMount() {
      if ((this.props.channel && !this.state.expanded && this.props.expandedList)) {
        this.setState({
          expanded: true
        });
        if (this.props.handlePanelHasExpanded && this.props.type) {
          this.props.handlePanelHasExpanded(true, this.props.type);  
        }
      }

      const panel = document.getElementById(this.props.panel);
      if (panel && panel.ariaExpanded == "true") {
        panel.scrollIntoView();
      }
    }


    componentDidUpdate(prevProps) {
      if ((!prevProps.channel && this.props.channel && !this.state.expanded && this.props.expandedList)) {
        this.setState({
          expanded: true
        });
        if (this.props.handlePanelHasExpanded && this.props.type) {
          this.props.handlePanelHasExpanded(true, this.props.type);  
        }
      }

      const panel = document.getElementById(this.props.panel);
      if (panel && panel.ariaExpanded == "true") {
        panel.scrollIntoView();
      }

    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
        if (this.props.handlePanelHasExpanded && this.props.type){
                this.props.handlePanelHasExpanded(expanded, this.props.type);  
        }
    };

    render() {
        return (
            <Accordion
                onChange={this.handleChange(this.props.title)}
                className="generic-panellist-main"
                expanded={this.state.expanded}  
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id={this.props.panel}>
                    <Typography className="generic-panellist-title">{this.props.title}</Typography>
                    <Typography className="generic-panellist-subtitle">{this.props.subtitle}</Typography>
                    {this.props.warning ? <div className="generic-panellist-warning">{this.props.warning}</div> : null}
                </AccordionSummary>
                <AccordionDetails>{this.props.content}</AccordionDetails>
            </Accordion>
        );
    }
}

export default PanelList;
