import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { handleSideBar } from '../Generic/actions/genericActions';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import CATALOGUES from '../../catalogues';
import Notifications from '../Generic/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';

import { getTranslate } from 'react-localize-redux';
import { Alert } from '@mui/material';
function HideOnScroll(props) {
	const { children, window } = props;

	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export const NavBar = ({ push, handleSideBar, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);

	function handleMobileMenuOpen(event) {
		setMobileMoreAnchorEl(event.currentTarget);
	}

	function handleNotificationsMenuClose() {
		setNotificationsAnchorEl(null);
	}

	function handleNotificationsMenuOpen(event) {
		setNotificationsAnchorEl(event.currentTarget);
	}

	return (
		<React.Fragment>
			<HideOnScroll {...props}>
				<AppBar
					sx={{
						backgroundColor: '#E6F0F9',
					}}
				>
					<Container maxWidth={false}>
						<Toolbar sx={{ padding: 1 }}>
							<img
								src={props.config.apihost + '/auth/clients/' + props.client.id + '/files/' + props.client.logo[0].uuid}
								srcSet={props.config.apihost + '/auth/clients/' + props.client.id + '/files/' + props.client.logo[0].uuid}
								alt={props.client.logo[0].uuid}
								loading="lazy"
								height={'50px'}
								onClick={() => push(props.whoami.redirect_url ? props.whoami.redirect_url : '/dashboard')}
								style={{ cursor: 'pointer' }}
							/>
							<Box sx={{ flexGrow: 1 }} />
							{props.whoami.scopes.includes('websocket.user.notifications') && (
								<Box sx={{ display: { xs: 'flex' } }}>
									<Notifications
										menuId={'notifications-bell'}
										isMenuOpen={isNotificationsMenuOpen}
										anchorEl={notificationsAnchorEl}
										handleMenuOpen={handleNotificationsMenuOpen}
										handleMenuClose={handleNotificationsMenuClose}
										translate={props.translate}
									/>
								</Box>
							)}
							<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
								{/* <IconButton size="large" aria-label="show 17 new notifications" color="primary">
									<Badge badgeContent={17} color="error">
										<NotificationsIcon />
									</Badge>
								</IconButton> */}

								<IconButton
									size="large"
									edge="end"
									aria-label="account of current user"
									// aria-controls={menuId}
									aria-haspopup="true"
									onClick={() => handleSideBar(true, CATALOGUES.NAVIGATION)}
									color="primary"
								>
									<MenuIcon />
								</IconButton>
							</Box>
							<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
								<IconButton
									size="large"
									edge="end"
									aria-label="account of current user"
									// aria-controls={menuId}
									aria-haspopup="true"
									onClick={() => handleSideBar(true, CATALOGUES.NAVIGATION)}
									color="primary"
								>
									<MenuIcon />
								</IconButton>
							</Box>
						</Toolbar>
					</Container>
					{props.persistent_alert.open && (
						<Alert
							action={
								<IconButton
									aria-label="refresh"
									color="inherit"
									size="small"
									onClick={() => {
										window.location.reload();
									}}
								>
									<RefreshIcon />
								</IconButton>
							}
							severity={props.persistent_alert.severity}
							{...(props.persistent_alert.action && props.persistent_alert.action)}
						>
							{props.persistent_alert.message}
						</Alert>
					)}
				</AppBar>
			</HideOnScroll>
			<Toolbar />
			{props.persistent_alert.open && <Alert />}
		</React.Fragment>
	);
};

NavBar.propTypes = {
	config: PropTypes.object.isRequired,
	client: PropTypes.object,
	whoami: PropTypes.object,
};

const mapStateToProps = (state) => ({
	config: state.config,
	client: state.settings.clients.find((client) => client.id === state.login.client.id),
	whoami: state.users.whoami,
	translate: getTranslate(state.localize),
	persistent_alert: state.generic.persistent_alert,
});

const mapDispatchToProps = {
	push,
	handleSideBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
